import React from 'react'
import {ImagesUrl} from './include'

const  Sidebar = (props:{title?:string})=> {
    return (
      
  <nav
  id="sidebarMenu"
  className="collapse d-lg-block sidebar collapse bg-white"
  >
<div className="position-sticky">
 <div className="list-group list-group-flush mx-3 mt-4">
   <a href="/dashboard"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Dashboard'?'active':''}`}
      aria-current="true"
      >
     <i className="fas fa-tachometer-alt fa-fw me-3"></i ><span>Dashboard</span>
   </a>
   <a href="/plans"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='New Investment'?'active':''}`}

      > <i className="fas fa-money-bill fa-fw me-3"></i ><span>Invest</span>
   </a>

   <a href="/reinvest"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Re-Invest'?'active':''}`}

      > <i className="fas fa-money-bill fa-fw me-3"></i ><span>Re-Invest</span>
   </a>

   <a
      href="/my-investments"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='My Investments'?'active':''}`}

      ><i className="fas  fa-chart-area fa-fw me-3"></i><span>My Investment</span></a
     >
   <a
      href="/my-earnings"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Earnings'?'active':''}`}
      ><i className="fas fa-chart-line fa-fw me-3"></i><span>My Earnings</span></a>


   <a
      href="/topup"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Top up'?'active':''}`}
      >
     <i className="fas fa-chart-pie fa-fw me-3"></i><span>Deposit Funds</span>
   </a>



   <a
      href="/payout"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Request Payout'?'active':''}`}
      ><i className="fas fa-chart-bar fa-fw me-3"></i><span>Withdrawals</span></a>



   <a
      href="/referrals"
      className={`list-group-item list-group-item-action py-2 ripple ${props.title==='Referrals'?'active':''}`}
      ><i className="fas fa-users fa-fw me-3"></i><span>Withdraw Referrals</span></a>


   <a
       href={'/?refferer='+window.location.href}
      className="list-group-item list-group-item-action py-2 ripple "
      ><i className="fas fa-door-open fa-fw me-3"></i><span>Logout</span></a>








 </div>
</div>
</nav>


        
    )
}

export default Sidebar
