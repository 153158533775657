import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl, Currency,  Token, Code} from '../components/include'
import Template from '../components/template'

import { useDispatch, useSelector } from 'react-redux'
import { FormatNumber } from '../components/globalFunction';

const  Plans = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [plan, setPlan] = useState([] as any[])
    const [balance, setBalance] = useState(0)



    const user  = useSelector((state:any) => state.rootReducer.user);
    //const balance  = useSelector((state:any) => state.rootReducer.balance);
    
    const [errors, setErrors] = useState({
        errorMessage:''
    });

    const handleChange = (event:ChangeEvent<HTMLInputElement>, index:number) =>{		
        let {name, value} = event.target;
        const pl =JSON.parse(JSON.stringify(plan)) 
		   pl[index][name] = value;
        setPlan(pl);
    }


interface itemlist{
    code:string, 
    planName:string,
     duration:number, 
     minPlan:number, 
     amount:number,
      maxPlan:number, 
      status:string,
       roiRate:number
}



const fetchStat =()=>{
       
  var sql ="Select  (select sum(amount) from tbl_deposit where status ='Completed' and userCode = '"+Code+"' ) as deposit, (select sum(amount) from tbl_payout_request where status ='Completed' and fromAccount ='Main Wallet' and userCode = '"+Code+"' ) as withdraw, (select sum(amountEarn) from tbl_earnings where earnType <> 'Capital' and   userCode = '"+Code+"' ) as investmentReturn, (select sum(investment) from tbl_user_investment where status='active' and  userCode = '"+Code+"' ) as invest   ";

  const fd = new FormData();
  fd.append("sql", sql);
  fd.append('jwt', Token);
  let url = ServerUrl+'/fetchBySql_controller.php'
  
  axios.post(url, fd, config).then(result=>{ 
    setBalance((Number(result.data[0].deposit)+Number(result.data[0].investmentReturn))-(Number(result.data[0].withdraw)+Number(result.data[0].invest)))
  })
  .catch((error)=>{
     // Alerts('Error!', 'danger', error.message)
  }).finally(()=>{
      setNotice({...notice, isDataFetching: false}) 
  })
}





    const submit =(event: FormEvent<HTMLFormElement>, item:itemlist)=>{
        event.preventDefault();
        var errormessage = [];


      if(Number(item.minPlan)!==0 && Number(item.amount) < Number(item.minPlan)){
        Alerts('Error!', 'danger', 'The amount invested is less than minimum!')
          errormessage.push('error');
      }

      if(Number(item.maxPlan)!==0 && Number(item.amount) > Number(item.maxPlan)){
        Alerts('Error!', 'danger', 'The amount invested is more than maximum!')
          errormessage.push('error');
      }

      if(Number(item.amount) > balance){
        Alerts('Error!', 'danger', 'The amount invested is more than your balance, go and top up!')
          errormessage.push('error');
      }

     if (errormessage.length===0) {
       
        setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        var perDay = ((Number(item.roiRate)/100) * Number(item.amount))


        var toReturn = (perDay * Number(item.duration)).toFixed(2)
        var startDate = new Date().toISOString().slice(0,19);
        const day = 1000 * 60 * 60 * 24 *  Number(item.duration)
        var date = new Date()

        var stopDate = new Date(date.setTime(date.getTime()+day)).toISOString().slice(0,19);
       
        fd.append('amount', item.amount.toString());
        fd.append('perDay', perDay.toFixed(2));
        fd.append('toReturn', toReturn);
        fd.append('startDate', startDate);
        fd.append('stopDate', stopDate);
        fd.append('code', 'i'+Math.random().toString(36).slice(2,9))
        fd.append('userCode', Code);
        fd.append('planCode', item.code);
        fd.append('jwt', Token);



        //referal bonus of 8%
        var bonus = 0.08 * Number(item.amount)
        fd.append('referBy', user.referBy);
        fd.append('username', user.username);
        fd.append('bonus', bonus.toFixed());


        let url = ServerUrl+'/save_controller.php?tablename=tbl_investment';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
            Alerts('Success!', 'success', 'You have successfully purchased a new investment contract!')
               window.open('/my-investments', '_self')
                  } else{
                
            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                setNotice({...notice,  isLoading: false })
              }) 

        } 
    }

    const fetchPlan =()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select code, planName, duration, minPlan, amount, maxPlan, status, roiRate from tbl_investment_plan where  status ='Active'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        axios.post(url, fd, config).then(result=>{ 

            setPlan(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
        
    }


    useEffect(()=>{
        fetchPlan() 
        fetchStat()
         },[]);

    return (<Template title="Re-Invest">


{notice.isDataFetching? <div className="loader"> <img src={ImagesUrl+"/loading.gif"} /> 
          </div>:
<div className="container pt-4">




                   {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

      <section className="text-center">
        <h4 className="mb-4"><strong>Investment Plans</strong></h4>

        <div className="btn-group mb-4" role="group" aria-label="Basic example">
         
        <button type="button" className="btn btn-primary">
          Main Balance:  <small>{FormatNumber(balance)+' '+Currency.title}</small>
          </button>
          <button type="button" className="btn btn-primary active">Referal Balance {FormatNumber(user.referal)+' '+Currency.title}</button>

         
        </div>

        <div className="row ">


          
{plan.length>0? plan.map((item, id)=>  <div key={id} className="col-lg-4 col-md-6 mb-4">

<form method="post" action="#!" id={"form"+id} onSubmit={(event)=>submit(event, item)} >
            <div className="card border border-primary">

              <div className="card-header bg-white py-3">
                <p className="text-uppercase small mb-2"><strong>{item.planName}</strong></p>
                <h5 className="mb-0">{item.roiRate}%</h5>
              </div>

              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Min: {Number(item.minPlan)!==0?item.minPlan:'Unlimited'} USD</li>
                  <li className="list-group-item"> Max: {Number(item.maxPlan)!==0?item.maxPlan + ' '+Currency.title:'Unlimited'}</li>
                  <li className="list-group-item">{item.duration} days</li>
                 

<div className="form-group bmd-form-group">
                    <div className="input-group mb-3">
                        
                        <div className="input-group-prepend">
                            <span className="input-group-text">{Currency.unicode}</span>
                        </div>


                        <input type="text" pattern="\d*" title="Must contain digit only" className="form-control pl-2"  value={item.amount}  onChange={(e)=>handleChange(e, id)}  required name="amount" placeholder="Enter amount..." />


                        <div className="input-group-append">
                            <span className="input-group-text">{Currency.title}</span>
                        </div>
                    </div>
                </div>
                </ul>
              </div>

              <div className="card-footer bg-white py-3">
               
    <button type="submit" className="btn btn-primary btn-block">Invest Now</button>
              </div>

            </div>
            
</form>
          </div>):''} 
          


        </div>
      </section>
    </div>}


</Template>
      
    )
}

export default Plans
