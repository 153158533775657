import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import Template from '../components/template'
import {ServerUrl,  config, ImagesUrl,  Token, Code, Currency} from '../components/include'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormatNumber } from '../components/globalFunction';

const  PaymentPlan = ()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [deposit, setDeposit] = useState([] as any[])
    

    const user  = useSelector((state:any) => state.rootReducer.user);
//to get transaction details
//https://api.commerce.coinbase.com/charges/coinbaseid

    let { chargeCode } = useParams<{ chargeCode: string }>();
    
    const fetchContent = async ()=>{
        setNotice({...notice, isDataFetching: true}) 
        var sql ="Select code, amount, status, mailSent, chargeId, coinbaseid from tbl_deposit where chargeId ='"+chargeCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        await axios.post(url, fd, config).then(result=>{ 
            setDeposit(result.data)  

            if(Array.isArray(result.data) && result.data[0].mailSent && result.data[0].mailSent ==='No'){
                const fd = new FormData();
                 fd.append("chargeId", chargeCode);
                 fd.append("amount", result.data[0].amount);
                 fd.append("username", user.firstname);
                 fd.append("email", user.email);
                fd.append('jwt', Token);
                
                let url = ServerUrl+'/save_controller.php?tablename=tbl_send_deposit_mail';
                axios.post(url, fd, config)
                .then(response =>{
                    //console.log('i sent a mail')
                }
                )
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching: false}) 
        })
        
    }

    const sendMail =(mailSent:string, amount:string)=>{
        if(mailSent ==='No'){
        const fd = new FormData();
         fd.append("chargeId", chargeCode);
         fd.append("amount", amount);
         fd.append("username", user.firstname);
         fd.append("email", user.email);
        fd.append('jwt', Token);
        
        let url = ServerUrl+'/save_controller.php?tablename=tbl_send_deposit_mail';
        axios.post(url, fd, config)
        .then(response =>{
            //console.log('i sent a mail')
        }
        )
    }
    }


   /*  useEffect(()=>{
        setTimeout(() => {
            sendMail(deposit[0].mailSent, deposit[0].amount);
        }, 5000) 
         },[]); */

    

    useEffect(()=>{
        fetchContent() 
         },[]);

    return (<Template title="Top up">
        <div className="container pt-4">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <h2 className="text-center">Pay with Crypto</h2>
                </div>
            </div>



                <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="card">
                        <div className="card-body">


                {deposit.length>0? deposit.map((item, id)=>   
                    <div key={id} className="jumbotron bg-white text-center">
                        <h1 className="display-3 text-dark">Top up - {FormatNumber(item.amount) +" "+Currency.title}</h1>
                        <p className="lead text-dark">Crypto payments are processed at coinbase</p>
                        <a href={'https://commerce.coinbase.com/charges/'+item.chargeId} className="my-3 btn btn-primary">Make Payment</a>
                        <hr className="my-4" />
                        <p className="text-small text-muted">This payment address is available for one hour! You should send the funds within this timeframe.</p>
                        <p className="lead">
                            <a className="btn btn-primary btn-lg" href="/topup" role="button">go back</a>
                        </p>
                    </div>):''}

                </div></div></div>
            </div>

        </div>
</Template>
      
    )
}

export default PaymentPlan
