import React, {useState, useEffect, FormEvent, ChangeEvent} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import axios from 'axios'
import Navbar from './navbar'
import Footer from './footer'
import Cookies from 'js-cookie'
import {encrypt} from '../components/authentication'

const  Login = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [user, setUser] = useState({
		email:'',
		password:'',
        twoFA:'',
		remember:'false',
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({
        errorMessage:''
    });

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{	
        let {name, value} = event.target;		
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }

                
     const submit =(event: FormEvent<HTMLFormElement>)=>{ 
        event.preventDefault();
		setNotice({...notice, isLoading: true}) 	
           

        const fd = new FormData();
        Object.entries(user).forEach(([key, value]) => {
            fd.append(key, value);
        }); 

           let url = ServerUrl+'/login_client_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
			  
			  localStorage.setItem('_vlifeinexpt', inSixtyMinutes.toString())
				localStorage.setItem('_vlifeinbexpt', inOneMinutes.toString())
			Cookies.set('vlifeinvcd', encrypt(response.data.code), {expires: inOneHours })
			Cookies.set('vlifeinvtk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			//Alerts('Welcome!', 'success', response.data.message)
      Cookies.set('vlifeinusnvcd', encrypt(response.data.username), {expires: inOneHours })
			var queryString = new URLSearchParams(window.location.search).get("refferer")
            if(queryString!==null){
                window.open(queryString, '_self')
            }else{
                window.open('/dashboard', '_self')
            }
			
			}else if (response.data.type ==='error'){
				setErrors({...errors, errorMessage: response.data.message})
        setNotice({...notice,  isLoading: false }) 
			}    
		})
		.catch((error)=>{
            setErrors({...errors, errorMessage: JSON.stringify(error.message, null, 2)})
            setNotice({...notice,  isLoading: false }) 
		})   
}



const clearCookies=()=>{
	Cookies.remove('vlifeinvcd')
	 Cookies.remove('vlifeinvtk')
   Cookies.remove('vlifeinusnvcd')
   
  localStorage.setItem('_vlifeinexpt', Date.now().toString())
  localStorage.setItem('_vlifeinbexpt', Date.now().toString())
}

useEffect(()=>{  
  clearCookies()
},[]);



    return (
        <> 
    <header>
       
        <div id="intro" className="bg-image shadow-2-strong">
          <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
            <div className="container">
               


            {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


              <div className="row justify-content-center ">
                <div className="col-xl-5 col-md-8">

                <button type="button" className="btn btn-warning btn-block " style={{height:'50px', fontSize:'20px', cursor:'default', top:'20px'}}> 
    <i className="fa fa-lock"></i> LOGIN  </button>

                  <form className="bg-white  rounded-5 shadow-5-strong p-5"  method="POST" onSubmit={submit}>
                    <div className="form-outlines mb-4">
                      
                      <input type="email" required name="email"  value={user.email}  onChange={handleChange} className='form-control pl-2 ' placeholder="Email Address"   />
                    </div>
                    <div className="form-outlines mb-4">
                     

                      <input type="password"  name="password"  value={user.password}  required  onChange={handleChange} className='form-control pl-2 ' placeholder="Password" /> 

                    </div>
    
                  
                      
                        {notice.isLoading?
          
          <button disabled className="btn btn-warning btn-link btn-lg" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:
                    <button type="submit"   className="btn btn-primary btn-block">Sign in</button>}
                 


                  <div className="row mb-4 mt-4">
                          <div className="col d-flex justify-content-center">
                          <div className="col text-center">
                            <a href="/register">Create Account</a>
                          </div>
                          </div>
        
                          <div className="col text-center">
                            <a href="/forgot_password">Forgot password?</a>
                          </div>
                        </div>
                        </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />

      
      
      </>
    )
}

export default Login
