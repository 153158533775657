import {useState, FormEvent, ChangeEvent} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import axios from 'axios'
import Footer from './footer'
import HtmlCountry from '../components/htmlCountry'

const  Register = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	 
    let Initials  = {
        code:'u'+Math.random().toString(36).slice(2,9),
		   email:'',
		    password:'',
        firstname:'',
        lastname:'',
        confirmPassword:'',
        username:'',
        telephone:'',
        country:''

    }

	const [user, setUser] = useState(Initials)
    const [errors, setErrors] = useState({
        successMessage:'',
        email:'',
        confirmPassword:'',
        errorMessage:''
    });

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
      let {name, value} = event.target;	
     setUser({...user, [name] : value });
     setErrors({...errors, [name]:''});
  }


	const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

      if(user.password !== user.confirmPassword){
          setErrors({...errors, confirmPassword:'Password does not match.'})
          errormessage.push('Password does not match.');
      }

        if(!user.email){
            setErrors({...errors, email:'The email entered is not avaialable'})
          errormessage.push('The email entered is not avaialable');
        }

        if (errormessage.length<=0) {
            submitFormData()
        }

    }

    
 function submitFormData(){  
            setNotice({...notice, isLoading: true})     
            const fd = new FormData();
             Object.entries(user).forEach(([key, value]) => {
                fd.append(key, value);
            }); 
            var queryString = new URLSearchParams(window.location.search).get("ref")
            
            if(queryString!==null){
                fd.append('ref', queryString);
            }else{
                fd.append('ref', '');
            }
            
           let url = ServerUrl+'/save_controller.php?tablename=tbl_new_users';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.type === 'success'){
                
              setErrors({...errors, successMessage: response.data.message})
                  // window.open('/login', '_self')
                      } else{
                    //Alerts('Error!', 'danger', response.data)
                     window.scrollTo(0,0)
             setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                      }   
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{

                    setUser(Initials)
                    setNotice({...notice,  isLoading: false })
                  })  

              }



              const ValidateExistence=()=>{
                if(user.email!==''){
                    const fd = new FormData();
                    fd.append("data", user.email)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_users&column=email';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, email:user.email + ' already exist'})
                            setUser({...user, email:''});
                            return '';
                        }
                  })
                }
            } 

    return ( <> 
        <div>
           
        
               
            <div id="intro" className="bg-image shadow-2-strong mt-3">
              <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container mt-10">
                   
    
{errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="success">
          <strong>Great!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

    
{errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

                  <div className="row justify-content-center ">
                    <div className="col-xl-5 col-md-8">
    
                    <button type="button" className="btn btn-warning btn-block " style={{height:'50px', fontSize:'20px', cursor:'default', top:'20px'}}> 
        <i className="fa fa-users "></i> REGISTER </button>
    
                      <form className="bg-white  rounded-5 shadow-5-strong p-5" method="post" onSubmit={submit}>
                        <div className="form-outlines mb-3">
                          
                        <input type="email" required placeholder="Email Address..." name="email" onBlur={ValidateExistence} value={user.email}  onChange={handleChange} role='presentation' autoComplete='off' className='form-control pl-2 '   />


                      <span style={{color: '#ff2e2e'}} >{errors.email }</span>
                        </div>


                        <div className="form-outlines mb-3">

        <input type="text"  name="username" role='presentation' autoComplete='off' placeholder="Username..." onBlur={ValidateExistence} value={user.username}  onChange={handleChange} className='form-control pl-2 ' required /> 

                        </div>


                        <div className="form-outlines mb-3">
                         
        <input type="text"  name="firstname" placeholder="First Name..." role='presentation' autoComplete='off' required value={user.firstname}   onChange={handleChange} className='form-control pl-2 ' /> 

                        </div>



                        <div className="form-outlines mb-3">

            <input type="text"  name="lastname" role='presentation' autoComplete='off' value={user.lastname} placeholder="Last Name..."   onChange={handleChange} className='form-control pl-2 ' required /> 

                        </div>

                        <div className="form-outlines mb-3">
                        
            <input type="text"  name="telephone" role='presentation' autoComplete='off' pattern="\d*" title="Must contain digit only" placeholder="Phone.."  value={user.telephone}  onChange={handleChange} className='form-control pl-2 ' required /> 

        
                        </div>

                        <div className="form-outlines mb-3">
                        <HtmlCountry handleSelect={handleChangeSelect} country={user.country}  />
                       
                    
                                    </div>


                        <div className="form-outlines mb-3">
                         
                          <input type="password" role='presentation' autoComplete='off'  name="password" placeholder="Password.."   value={user.password} required  onChange={handleChange} className='form-control pl-2 ' /> 



        <span style={{color: '#ff2e2e'}}>{ errors.confirmPassword}</span>
                        </div>



                        <div className="form-outlines mb-4">
                         
                        <input type="password" role='presentation' autoComplete='off'  required  name="confirmPassword" placeholder="Repeat Password"  value={user.confirmPassword}  onChange={handleChange} className='form-control pl-2 ' /> 



                        <span  style={{color: '#ff2e2e'}}>{ errors.confirmPassword}</span>
                        </div>
        
        
                      
                        {notice.isLoading?
          
          <button disabled className="btn btn-warning btn-link btn-lg" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:
                    <button type="submit"   className="btn btn-primary  btn-lg btn-block">Create Account</button>}



<div className="row mt-4">
                          <div className="col d-flex justify-content-center">
                            <div className="form-checks">
                              <label className="form-check-label" htmlFor="form1Example3">
                              <a href="/">Login</a>
                              </label>
                            </div>
                          </div>
        
                          <div className="col text-center">
                            <a href="/forgot_password">Forgot Password?</a>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          
          </>
          
          
    )
}

export default Register
