import React, {useState, FormEvent, ChangeEvent} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config,  ImagesUrl} from '../components/include'
import Navbar from './navbar'
import Footer from './footer'
import axios from 'axios'

const  Forget = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false
    });
	const [user, setUser] = useState({
		email:''
    })
    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });
    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{	
        let {name, value} = event.target;
       setUser({...user, [name] : value });
    }

  
        const submit =(event: FormEvent<HTMLFormElement>)=>{ 
                event.preventDefault();
                setNotice({...notice, isLoading: true}) 	
          
        const fd = new FormData();
        fd.append('email', user.email)

        let url = ServerUrl+'/save_controller.php?tablename=tbl_reset_password';
		axios.post(url, fd, config)
		.then(response =>{
	if(response.data.type === 'success'){

    setErrors({...errors, successMessage: response.data.message})

			}else{
          setErrors({...errors, errorMessage: response.data.message})
			}    
		})
		.catch((error)=>{
			  setErrors({...errors, errorMessage: error.message})
		}).finally(()=>{
            setNotice({...notice,  	isLoading: false }) 
            setUser({email:''})
        })     
}



    return ( <>
    <header>
       
    
    
               
            <div id="intro" className="bg-image shadow-2-strong">
              <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container">
                   


{errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="success">
          <strong>Warning!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}

{errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-danger" role="alert" data-mdb-color="danger">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


                  <div className="row justify-content-center ">
                    <div className="col-xl-5 col-md-8">
    
                    <button type="button" className="btn btn-warning btn-block " style={{height:'50px', fontSize:'20px', cursor:'default', top:'20px'}}> 
        <i className="fa fa-lock"></i> FORGET PASSWORD </button>
    
                      <form className="bg-white  rounded-5 shadow-5-strong p-5" method="POST" onSubmit={submit}>
                      <div className="form-outlines mb-4">
                      
                      <input type="email" required name="email"  value={user.email}  onChange={handleChange} className='form-control pl-2 ' placeholder="Email address... "  />

                    </div>

         {notice.isLoading?
          
          <button disabled className="btn btn-warning btn-block btn-lg" type="button">
              
              
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:    <button type="submit" className="btn btn-primary btn-block  ">Send Password Reset Link</button>}



              <div className="row mt-4">
                          <div className="col d-flex justify-content-center">
                            <div className="form-checks">
                              <label className="form-check-label" htmlFor="form1Example3">
                              <a href="/">Login</a>
                              </label>
                            </div>
                          </div>
        
                          <div className="col text-center">
                            <a href="/register">Create Account</a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <Footer />
          </>
    )
}

export default Forget
