import React, {useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { Alerts } from '../components/notify';
import axios from 'axios'
import {ServerUrl,  config, ImagesUrl, Currency,  Token, Code} from '../components/include'
import Template from '../components/template'
import { useSelector } from 'react-redux'
import { FormatNumber } from '../components/globalFunction';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const  Payout = ()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [deposit, setDeposit] = useState([] as any[])

    const user  = useSelector((state:any) => state.rootReducer.user);
    const [withdrawal, setWithdrawal] = useState({
        method:'Bitcoin',
        amount:'',
        information:'',
        account:'',
        twoFA:'',
        userCode:Code,
        code:'p'+Math.random().toString(36).slice(2,9)
    })

    const [balance, setBalance] = useState(0)
    //var balance = Number(user.balance)
    const [errors, setErrors] = useState({
        errorMessage:'',
        successMessage:''
    });
    


const fetchStat =()=>{
       
    var sql ="Select  (select sum(amount) from tbl_deposit where status ='Completed' and userCode = '"+Code+"' ) as deposit, (select sum(amount) from tbl_payout_request where status ='Completed' and fromAccount ='Main Wallet' and userCode = '"+Code+"' ) as withdraw, (select sum(amountEarn) from tbl_earnings where earnType <> 'Capital' and   userCode = '"+Code+"' ) as investmentReturn, (select sum(investment) from tbl_user_investment where status='active' and  userCode = '"+Code+"' ) as invest   ";
  
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    
    axios.post(url, fd, config).then(result=>{ 
      setBalance((Number(result.data[0].deposit)+Number(result.data[0].investmentReturn))-(Number(result.data[0].withdraw)+Number(result.data[0].invest)))
    })
    .catch((error)=>{
       // Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isDataFetching: false}) 
    })
  }


  

    const handleMethod = (methodName:string)=>{
        setWithdrawal({...withdrawal, method : methodName });
        document.getElementById('modalBtn')?.click()
    }
    
    

    const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setWithdrawal({...withdrawal, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setWithdrawal({...withdrawal, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeTextArea = (event:ChangeEvent<HTMLTextAreaElement>) =>{		
        let {name, value} = event.target;	
        setWithdrawal({...withdrawal, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        var errormessage = [];

        if (withdrawal.account === 'Main Wallet'){

            if(Number(withdrawal.amount) > Number(user.balance)){
                errormessage.push('error');
            Alerts('Error!', 'danger', 'The amount to withdraw is more than your balance');
              
            }
        }

          
        if (withdrawal.account === 'Referral Earning'){
            if (Number(withdrawal.amount) > Number(user.referal)){
                Alerts('Error!', 'danger', 'The amount to withdraw is more than your balance')
                  errormessage.push('error');
                }

            }




        if (Number(withdrawal.amount) > 100000){
        Alerts('Oops!', 'danger', ' Your request amount is higher than the maximum for the selected payout method!.')
              errormessage.push('error');
            }


            if (Number(withdrawal.amount) <= 0){
                Alerts('Oops!', 'danger', ' Your request amount is less than the minimum for the selected payout method!.')
                      errormessage.push('error');
                    }

    
        
    if (user.isTwoFA==='Active' && withdrawal.twoFA !== user.twoFA){
        document.getElementById('closeModal')?.click()
        setErrors({...errors, errorMessage: 'The 2FA entered is  does not match'})
              errormessage.push('error');
        }

       
     if (errormessage.length <=0) {
        var main = Number(user.balance)         
        var ref =  Number(user.referal)
        
        if (withdrawal.account === 'Main Wallet'){
            main = Number(user.balance) - Number(withdrawal.amount)
        }

        if (withdrawal.account === 'Referral Earning'){
            ref = Number(user.referal) - Number(withdrawal.amount)
        }


       setNotice({...notice, isLoading: true})     
        const fd = new FormData();
        Object.entries(withdrawal).forEach(([key, value]) => {
            fd.append(key, value);
        }); 
        fd.append('jwt', Token);
        fd.append('username', user.firstname);
        fd.append('email', user.email);
        fd.append('balance', main.toFixed());
        fd.append('refbalance', ref.toFixed());
        let url = ServerUrl+'/save_controller.php?tablename=tbl_payout';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type === 'success'){
         setErrors({...errors, successMessage: 'Your payout request was successfully received!.'})
               
                  } else{
         setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

            window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                document.getElementById('closeModal')?.click()
                setNotice({...notice,  isLoading: false })
                setWithdrawal({
                    method:'Bitcoin',
                    amount:'',
                    information:'',
                    account:'',
                    twoFA:'',
                    userCode:Code,
                    code:'p'+Math.random().toString(36).slice(2,9)
                })
                fetchContent() 
              })   
        } 
    }


var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable: false, formatter: (cell:Number)=>i++},
        {dataField: 'fromAccount', text: 'From', editable: false},
        {dataField: 'method', text: 'Method', editable: false},
        {dataField: 'amount', text: 'Amount', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+" "+Currency.title},

        {dataField: 'fee', text: 'Fee', editable: false, formatter: (cell:Number)=>FormatNumber(cell)+" "+Currency.title},
        {dataField: 'information', text: 'Information', editable: false, formatter:(cell:number)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}> {cell}</span>},

        {dataField: 'createdAt', text: 'Created at', editable: false},
        {dataField: 'updatedAt', text: 'Updated at', editable: false},
        {dataField: 'status', text: 'Status', editable: false, formatter:(cell:string)=><span 
    
        className={` badge 
        ${cell==='Active'?'bg-success':
        cell==='Canceled'?'bg-danger':
        cell==='Pending'?'bg-warning':
        cell==='Completed'?'bg-secondary':
                        'btn-info'}`}> {cell}</span>},
              
     ];

  
     const BasicTable=(props:{data:any, columns:any})=>{
             
              const customTotal = (from:Number, to:Number, size:Number) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={'No payout requests'}
                                     pagination={paginationFactory(options) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      const fetchContent =()=>{
          setNotice({...notice, isDataFetching: true}) 
          var sql ="Select ID, code, fee, fromAccount, method, amount, information,	status,	userCode, createdAt,	updatedAt from tbl_payout_request where userCode ='"+Code+"' order by ID DESC";
  
          const fd = new FormData();
          fd.append("sql", sql);
          fd.append('jwt', Token);
          let url = ServerUrl+'/fetchBySql_controller.php'
          
          axios.post(url, fd, config).then(result=>{ 
              setDeposit(result.data)
          })
          .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isDataFetching: false}) 
          })
      }

    useEffect(()=>{
        fetchContent() 
        fetchStat()
         },[]);


    
    return (<Template title="Request Payout">
     
{notice.isDataFetching? <div className="loader"> <img src={ImagesUrl+"/loading.gif"} /> 
          </div>: <div className="container pt-4">

    
                 
                 
                   {errors.successMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-success" role="alert" data-mdb-color="success">
          <strong>Success!</strong> {errors.successMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


                   {errors.errorMessage?
       <div className="container">
        <div className="alert alert-dismissible fade show alert-warning" role="alert" data-mdb-color="warning">
          <strong>Warning!</strong> {errors.errorMessage}
          <button type="button" className="btn-close" data-mdb-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>:''}


<div className="row">
    <div className="col-lg-12">
        <a href="#payouts" className="btn btn-lg btn-block btn-info">View My Payout Requests<div className="ripple-container"></div></a>
    </div>

</div>

<br/>

<div className="row justify-content-center">
    <div className="col-lg-12">
        <h2 className="text-center">Choose payout method</h2>
    </div>
</div>



{/* Withdrawal modal here  */}

<div className="modal fade" id="method2" tabIndex={-1} >
                            <div className="modal-dialog" role="document">
                            
<form method="post" action="#" onSubmit={submit} >
                               
                                <div className="modal-content">
                                
                                    <div className="modal-header">
                                        <h5 className="modal-title">Withdraw funds via <ins>{withdrawal.method}</ins></h5>

                                        <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close">
                                        </button>
                                    </div>
                                   
                                        
                                    <div className="modal-body">
                                      
                                        <div className="btn-group mb-4" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-primary">
          Main Balance:  <small>{FormatNumber(balance)+' '+Currency.title}</small>
          </button>  
          
          <button type="button" className="btn btn-primary active">Referal Balance {FormatNumber(user.referal)+' '+Currency.title}</button>

          
        </div>


                                  <div className="form-group bmd-form-group">
                                                <label htmlFor="amount" className="bmd-label-static">Amount({Currency.title})</label>
                    <input type="number" id="amount2" pattern="\d*" min="0" className="form-control" name="amount" placeholder="100"  value={withdrawal.amount}  onChange={handleChange} required />

                                <p className="receiveText2 hidden float-right">You receive: <span className="bold text-success">{FormatNumber(Number(withdrawal.amount))}</span></p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="account">Withdraw From</label>
                        
                         <select name="account" id="account" value={withdrawal.account}   onChange={handleChangeSelect} className="form-control" required>
                <option value="">Select</option>                                                                      <option value="Main Wallet">Main Wallet</option>                    
                            <option value="Referral Earning">Referral Earning</option>
                                            </select>

                                            </div>

                <div className="form-group bmd-form-group mt-4">
                    <label htmlFor="info" className="bmd-label-static">Information (Example Receiving Address)</label>
                    <textarea name="information" required className="form-control" value={withdrawal.information}  onChange={handleChangeTextArea}  cols={15} rows={5} placeholder="Information related to the payout method">{withdrawal.information}</textarea>
                </div>

                <div className="form-group bmd-form-group mt-3">
                    <label htmlFor="2fa" className="bmd-label-static">2FA</label>
                    <input type="text" onChange={handleChange} value={withdrawal.twoFA} className="form-control" name="code" placeholder="Only if 2FA is enabled..." />
                </div>
                     </div>


                                    <div className="modal-footer">

                                 
                {notice.isLoading?
          
          <button disabled className="btn  btn-lg btn-success" type="button">
              <img src={ImagesUrl+"/loading.gif"} /> 
              Please wait...</button>:   <button type="submit" className="btn  btn-lg btn-success">Withdraw funds</button>  }
                        <button type="button" id="closeModal" className="btn btn-lg btn-secondary" data-mdb-dismiss="modal">Close</button>


                                    </div>
                                    </div>
</form>
                                   

                                
                            </div>
                </div>






<div className="row">
        
            <div className="col-lg-4">
                <div className="card border border-success">
                    <div className="card-header text-center">
                        <h3 className="header-title">Bitcoin</h3>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            <img src={ImagesUrl+"/bitcoin.png"}  width="80" />
                        </div>
                        <hr/>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Min. Withdraw
                                <span className="badge bg-primary ">0.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Max. Withdraw
                                <span className="badge bg-primary badge-pill">100,000.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Withdrawal Fee
                                <span className="badge bg-primary badge-pill">0.00 %</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Duration
                                <span className="badge bg-primary badge-pill">2 day(s)</span>
                            </li>
                        </ul>

                      

                        <button id="modalBtn" data-mdb-toggle="modal" style={{display:'none'}} data-mdb-target="#method2"></button>

    <button className="btn btn-lg btn-block btn-success mt-4" onClick={()=>handleMethod('Bitcoin')} >Withdraw Funds</button>
                        




                    </div>
                </div>
                <br/>
            </div>

        
            <div className="col-lg-4">
                <div className="card border border-success">
                    <div className="card-header text-center">
                        <h3 className="header-title">Ethereum</h3>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            <img src={ImagesUrl+"/ethereum.png"}  width="80" />
                        </div>
                        <hr/>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Min. Withdraw
                                <span className="badge bg-primary badge-pill">0.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Max. Withdraw
                                <span className="badge bg-primary badge-pill">100,000.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Withdrawal Fee
                                <span className="badge bg-primary badge-pill">0.00 %</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Duration
                                <span className="badge bg-primary badge-pill">2 day(s)</span>
                            </li>
                        </ul>

                        <button className="btn btn-lg btn-block btn-primary btn-success mt-4" onClick={()=>handleMethod('Ethereum')} >Withdraw Funds</button>
                       
                    </div>
                </div>
                <br/>
            </div>

        
            <div className="col-lg-4">
                <div className="card border border-success">
                    <div className="card-header text-center">
                        <h3 className="header-title">Perfect Money</h3>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            <img src={ImagesUrl+"/perfect.png"} width="80" height="25px" />
                        </div>
                        <hr/>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Min. Withdraw
                                <span className="badge bg-primary badge-pill">0.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Max. Withdraw
                                <span className="badge bg-primary badge-pill">100,000.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Withdrawal Fee
                                <span className="badge bg-primary badge-pill">0.00 %</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Duration
                                <span className="badge bg-primary badge-pill">2 day(s)</span>
                            </li>
                        </ul>

                        <button className="btn btn-lg btn-block btn-primary btn-success mt-4" onClick={()=>handleMethod('Perfect Money')} >Withdraw Funds</button>
                       
                    </div>
                </div>
                <br/>
            </div>

        
            <div className="col-lg-4">
                <div className="card border border-success">
                    <div className="card-header text-center">
                        <h3 className="header-title">Bitcoin Cash</h3>
                    </div>
                    <div className="card-body">
                        <div className="text-center">
                            <img src={ImagesUrl+"/cash.png"} width="80" />
                        </div>
                        <hr/>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Min. Withdraw
                                <span className="badge bg-primary badge-pill">0.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Max. Withdraw
                                <span className="badge bg-primary badge-pill">100,000.00 {Currency.title}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Withdrawal Fee
                                <span className="badge bg-primary badge-pill">0.00 %</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Duration
                                <span className="badge bg-primary badge-pill">2 day(s)</span>
                            </li>
                        </ul>

                        <button className="btn btn-lg btn-block btn-primary btn-success mt-4" onClick={()=>handleMethod('Bitcoin Cash')} >Withdraw Funds</button>
                        
                        
                    </div>
                </div>
                <br/>
            </div>

        
    
</div>

<div id="payouts">
    <div className="row">

        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                <div className="card-header">
                            <h5 className="header-title text-center">Payout Request</h5>
                        </div>
                    <div className="table-responsive">
                    <BasicTable data={deposit} columns={tableHeader}  />
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</div>

</div>}
</Template>
      
    )
}

export default Payout
