import React, {useState, useEffect} from 'react'

import {config, ServerUrl, Token, Code, ImagesUrl } from './include';
import axios from 'axios'
import Cookies from 'js-cookie'

import { useDispatch } from 'react-redux';
const Topbar = (props:{title?:string})=> {

  const dispatch = useDispatch()

const fetchContent = async()=>{
    if(Cookies.get('vlifeinvcd')){
    var sql ="Select username, firstname, lastname, email, referal, balance, twoFA, isTwoFA, referBy from tbl_users where code ='"+Code+"'";
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    const {data} = await axios.post(url, fd, config)
    
     dispatch({
      type:'SET_RECORDS',
      name:'user',
      data:data[0]
  })
    }else{
      window.location.href='/?refferer='+window.location.href
    }
}

const fetchStat = async () => {
  var sql ="Select  (select sum(amount) from tbl_deposit where status ='Completed' and userCode = '"+Code+"' ) as deposit, (select sum(amount) from tbl_payout_request where status ='Completed' and fromAccount ='Main Wallet' and userCode = '"+Code+"' ) as withdraw, (select sum(amountEarn) from tbl_earnings where   userCode = '"+Code+"' ) as investmentReturn, (select sum(investment) from tbl_user_investment where status='active' and  userCode = '"+Code+"' ) as investment, (select sum(investment) from tbl_user_investment where  userCode = '"+Code+"' ) as invest   ";

  const fd = new FormData();
  fd.append("sql", sql);
  fd.append('jwt', Token);
  let url = ServerUrl+'/fetchBySql_controller.php'
  const {data} = await axios.post(url, fd, config)
    
     dispatch({
      type:'SET_RECORDS',
      name:'balance',
      data:Number(data[0].deposit)+Number(data[0].investmentReturn)-Number(data[0].withdraw)-Number(data[0].invest)
  })
}


useEffect(()=>{
   fetchContent() 
   fetchStat()
    },[]);



    return ( <nav
id="main-navbar"
className="navbar navbar-expand-lg navbar-light bg-white "
>
  {/* fixed-top */}
<div className="container-fluid">
<button
       className="navbar-toggler"
       type="button"
       data-mdb-toggle="collapse"
       data-mdb-target="#sidebarMenu"
       aria-controls="sidebarMenu"
       aria-expanded="false"
       aria-label="Toggle navigation"
       >
 <i className="fas fa-bars"></i>
</button>

<a className="navbar-brand" href="#">
<img src={ImagesUrl+"/log.png"} style={{height: '40px'}} loading="lazy" />
</a>
<form className="d-none d-md-flex input-group w-auto my-auto">
 {/* <input
        autoComplete="off"
        type="search"
        className="form-control rounded"
        placeholder='Search (ctrl + "/" to focus)'
        style={{minWidth: '225px'}}
        /> */}


 <span className="input-group-text border-0"
       ><i className="fas fa-search"></i ></span>
</form>

<ul className="navbar-nav ms-auto d-flex flex-row">



<a href='/payout' className="btn btn-warning" type='button'>Withdraw </a>&nbsp;

<a href='/topup' className="btn btn-success" type='button'>Deposit</a>

<div id="google_translate_element"></div>


 <li className="nav-item dropdown">
   <a
      className="nav-link me-3 me-lg-0 dropdown-toggle hidden-arrow"
      href="#"
      id="navbarDropdownMenuLink"
      role="button"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
      >
     <i className="fas fa-bell"></i>
     <span className="badge rounded-pill badge-notification bg-danger" >1</span  >
   </a>
   <ul
       className="dropdown-menu dropdown-menu-end"
       aria-labelledby="navbarDropdownMenuLink"
       >
     <li><a className="dropdown-item" href="#">New Message</a></li>
    
   </ul>
 </li>

 
 <li className="nav-item dropdown">
   <a
      className="nav-link dropdown-toggle hidden-arrow d-flex align-items-center"
      href="#"
      id="navbarDropdownMenuLink"
      role="button"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
      >
     <img
          src={ImagesUrl+"/Male.png"}
          className="rounded-circle"
          height="22"
          alt=""
          loading="lazy"
          />
   </a>
   <ul
       className="dropdown-menu dropdown-menu-end"
       aria-labelledby="navbarDropdownMenuLink"
       >
     <li><a className="dropdown-item" href="/profile">My profile</a></li>
     <li><a className="dropdown-item" href="/referrals">Referrals</a></li>
     <li><a className="dropdown-item" href="/authenticator">2FA</a></li>

     <li className="dropdown-divider"></li>
     <li><a className="dropdown-item" href={'/?refferer='+window.location.href}>Log Out</a></li>

   </ul>
 </li>
</ul>
</div>
</nav>
    )
}

export default Topbar
